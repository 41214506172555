import { inject, Injectable } from '@angular/core';
import {
  addDoc, arrayRemove, arrayUnion, collection, collectionData, connectFirestoreEmulator,
  deleteDoc, doc, docData, Firestore, getFirestore,
  limit, orderBy, query, setDoc, updateDoc, where
} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { firstValueFrom, map, shareReplay, tap } from 'rxjs';
import { Office } from '../core/models/office';
import { Customer } from '../core/models/customer';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  private db: Firestore = inject(Firestore);
  private subscriptionRef = collection(this.db, 'subscriptions');

  constructor(private auth: AuthService) {
    if (environment.useEmulators.firestore) {
      connectFirestoreEmulator(this.db, 'localhost', 8080);
    }
    console.log("db", this.db);

  }

  getUserInformation() {
    const docRef = doc(this.db, `users/${this.auth.user?.uid}`);
    return docData(docRef).pipe(shareReplay(1), tap(() => console.log("getUserInformation")));
  }

  updateContactInformation(contact: any) {
    const docRef = doc(this.db, `users/${this.auth.user?.uid}`);
    return setDoc(docRef, Object.assign({}, { contact: contact }), { merge: true });
  }

  createOffice(office: Office) {
    const colRef = collection(this.db, 'offices');
    return addDoc(colRef, Object.assign({}, office));
  }
  getOffice(officeId: string) {
    const docRef = doc(this.db, `offices/${officeId}`);
    return docData(docRef, { idField: 'id' }).pipe(shareReplay(1), tap(() => console.log("getOffice")));
  }
  addSubscription(subscription: any) {
    return addDoc(this.subscriptionRef, Object.assign({}, subscription));
  }

  deleteSubscription(subscriptionId: string) {
    const docRef = doc(this.db, 'subscriptions', subscriptionId);
    return deleteDoc(docRef);
  }

  updateSubscription(subscription: any) {
    const docRef = doc(this.db, 'subscriptions', subscription.id);
    return setDoc(docRef, Object.assign({}, subscription), { merge: true });
  }

  getProducts() {
    const colRef = collection(this.db, 'products');
    const q = query(colRef, where('active', '==', true));
    return collectionData(q, { idField: 'id' });
  }
  getProductPrice(productId: string) {
    const docRef = collection(this.db, `products/${productId}/prices`);
    const q = query(docRef,
      where('active', '==', true),
      limit(1)
    )
    return collectionData(q, { idField: 'id' });
  }
  saveCreditCard(officeId: string, creditCard: any) {
    const colRef = collection(this.db, `offices/${officeId}/creditcards`);
    return addDoc(colRef, Object.assign({}, creditCard));
  }
  updateCreditCard(officeId: string, creditCard: any) {
    const docRef = doc(this.db, `offices/${officeId}/creditcards/${creditCard.id}`);
    return setDoc(docRef, Object.assign({}, creditCard), { merge: true });
  }

  giveAccessToCreditCard(officeId: string, creditCardId: string, employeeId: string) {
    const docRef = doc(this.db, `offices/${officeId}/creditcards/${creditCardId}`);
    return updateDoc(docRef, { accessTo: arrayUnion(employeeId) });
  }

  deleteAccessToCreditCard(officeId: string, creditCardId: string, employeeId: string) {
    const docRef = doc(this.db, `offices/${officeId}/creditcards/${creditCardId}`);
    return updateDoc(docRef, { accessTo: arrayRemove(employeeId) });
  }

  deleteCreditCard(officeId: string, creditCardId: string) {
    const docRef = doc(this.db, `offices/${officeId}/creditcards/${creditCardId}`);
    return deleteDoc(docRef);
  }

  getCreditCards(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/creditcards`);
    const q = query(colRef, where("accessTo", "array-contains", this.auth.user?.uid));
    return collectionData(q, { idField: 'id' });
  }

  addEmployee(officeId: string, employee: any) {
    const colRef = doc(this.db, `offices/${officeId}/employees/${employee.id}`);
    return setDoc(colRef, Object.assign({}, employee), { merge: true });
  }
  updateEmployee(officeId: string, employee: any) {
    const colRef = doc(this.db, `offices/${officeId}/employees/${employee.id}`);
    return setDoc(colRef, Object.assign({}, employee), { merge: true });
  }
  inviteEmployee(officeId: string, employee: any) {
    const officeRef = doc(this.db, `offices/${officeId}/employees`);
    return setDoc(officeRef, employee, { merge: true });
  }
  getInvites(officeId: string) {
    const colRef = collection(this.db, `invites`);
    const q = query(colRef, where("officeId", "==", officeId), orderBy("createAt", "desc"));
    return collectionData(q, { idField: 'id' });
  }
  getEmployees(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/employees`);
    const q = query(colRef, orderBy("active", "desc"));
    return collectionData(q, { idField: 'id' });
  }
  getSubscriptions(from?: any) {
    const colRef = collection(this.db, `users/${this.auth.user?.uid}/subscriptions`);
    const q = query(colRef, where("status", "==", "active"));
    return collectionData(q, { idField: 'id' }).pipe(shareReplay(1), tap((subs) => console.log("getSubscriptions", from, subs)))
  }
  getInvoices(subId: string) {
    const colRef = collection(this.db, `users/${this.auth.user?.uid}/subscriptions/${subId}/invoices`);
    return collectionData(colRef, { idField: 'id' });
  }
  getCourtCosts() {
    const colRef = collection(this.db, `court_costs`);
    const q = query(colRef, orderBy("disputed_value", "asc"));
    return collectionData(q, { idField: 'id' }).pipe(shareReplay(1), tap(() => console.log("getCourtCosts")));
  }

  getKostenmarken(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/kostenmarken`);
    const q = query(colRef, orderBy("createAt", "desc"));
    return collectionData(q, { idField: 'id' });
  }
  async createCheckoutSession(product: any) {
    const user: Customer | any = await firstValueFrom(this.getUserInformation());

    let sessionObj = {
      mode: product.price[0].type == 'recurring' ? 'subscription' : 'payment',
      client_reference_id: this.auth.user?.uid,
      customer: user.stripeId,
      locale: 'de',
      line_items: [
        {
          price: product.price[0].id,
          quantity: 1,
        },
      ],
      automatic_tax: {
        enabled: true,
      },
      promotion_code: product.price[0].type == 'recurring' ? null : 'promo_1PPiOkBmzLI87PzTqUyYyB8g',
      allow_promotion_codes: product.price[0].type == 'recurring' ? false : true,
      /* metadata: {
        trial_period_days: 30,
        trial_settings: {
          end_behavior: {
            missing_payment_method: 'cancel',
          },
        }
      }, 
      payment_method_collection: 'if_required',*/
      expires_at: Math.floor(Date.now() / 1000) + 60 * 45,
      success_url: window.location.origin + '/#/payment/success',
      cancel_url: window.location.origin + "/#/account/abonnements" //'https://localhost:3000/#/account/abonnements',
    }
    const colRef = collection(this.db, `users/${this.auth.user?.uid}/checkout_sessions`);
    return addDoc(colRef, Object.assign({}, sessionObj));
  }
}
